import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import About from "../views/AboutView.vue";

const routes = [
  // home and about routing
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    component: About,
  },

  // jobs routing
  {
    path: "/jobs",
    name: "jobs",
    component: () => import("../views/jobs/Jobs.vue"),
  },
  {
    path: "/jobs/:id",
    name: "jobDetails",
    component: () => import("../views/jobs/JobDetails.vue"),
  },

  //404 handling
  {
    path: "/:catchAll(.*)",
    name: "notFound",
    component: () => import("../views/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
